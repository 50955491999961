
<template>
  <div class="flex vertical full-height">
    <div :class="'scrollable-container main ' + (isContentDbAvailable && $store.state.ui.isAssortmentEnabled ? '' :  ' disabled')">

      <!--
        <pre>{{ disabledOptions }}</pre>
        <pre>{{ assortment.errors }}</pre>
      -->
      <!-- <hr />
      <pre>{{ JSON.stringify(configuration, null, 2) }}</pre> -->
      <!-- <pre>{{ JSON.stringify(optionsForKeyMap, null, 2) }}</pre> -->
      <!-- {{ optionKeys }} -->

      <div class="steps">
        <ul>
          <li v-for="(item, index) in steps"
              v-bind:key="index"
              :class="(currentStepKey == item.key ? 'active' : '')"
              @touchstart="currentStepKey = item.key"
              @mousedown="currentStepKey = item.key">
            <div class="title">{{ item.title }}</div>
          </li>
        </ul>
      </div>
      <p v-if="!editingAllowed" class="insufficient-rights-notice">
        Hinweis: Das Sortiment kann nur von einem Teamleiter oder Manager gepflegt werden.
      </p>
      <p v-if="!isContentDbAvailable">Die Konfigurationsdaten werden geladen, einen Augeblick bitte.</p>
      <WidgetRow class="assortment-config" gridTemplateColumns="1fr" flexGrow="1">
        <div class="accordions">
          <AccordionTile v-for="key in optionKeysForStepKey(currentStepKey)" v-bind:key="key">
            <template #title>
              <div>                
                <h5>{{ titleForKey(key) }}</h5>
                <span class="description" v-if="isGroupForKey(key)">Gruppen-Optionen</span>
                <span class="description" v-else-if="isAllOptionsChecked[key]">Alle Optionen ausgewählt</span>
                <div class="error-description" v-else-if="assortment.errors[key]"><span class="description error" >{{ assortment.errors[key] || "" }}</span></div>
                <span class="description" v-else>Anpassungen vorgenommen</span>
              </div>
            </template>
            <template #main>   
              <ul>                
                <li v-if="!isAllCheckboxHidden(key) ">
                  <input type="checkbox"
                    :checked="isAllOptionsChecked[key]"
                    :disabled="!editingAllowed || (implicitlyDisabledOptions[key] && implicitlyDisabledOptions[key]['_all'] || isAllOptionsNonSelectable(key))"
                    @change="toggleAllOptionsForKey(key, $event)"
                  >
                  <b>Alle</b><br><br>
                </li>                
                <li
                  v-for="(value, index) in visibleOptions[key]"
                  v-bind:key="index"
                  :class="(implicitlyDisabledOptions?.[key]?.[value] ? 'disabled' : '') + (isGroupForKey(value) ? ' group' : '') + (isHidden(value) ? ' hidden' : '')">
                  
                  <!-- <pre>{{  !disabledOptions?.[key]?.[value] }}</pre> | -->
                  <!-- <pre>{{  !implicitlyDisabledOptions?.[key]?.[value] }}</pre> -->
                      
                  <input type="checkbox"
                    v-if="!isGroupForKey(value)"
                    :disabled="!editingAllowed || implicitlyDisabledOptions?.[key]?.[value] || isNotSelectable(value)"
                    :value="!disabledOptions?.[key]?.[value]"
                    :checked="!disabledOptions?.[key]?.[value] && !implicitlyDisabledOptions?.[key]?.[value]"
                    @change="onSelectOption(key, value, $event)">
                  <div v-if="!isGroupForKey(value)" class="thumbnail" :style="thumbnailStyle(value)"></div>
                  <div class="text">
                    <span class="title">{{ titleForKey(value) }}</span>                    
                    <br>
                    <span class="description">{{ descriptionForKey(value) }}&nbsp;</span>
                    <span v-if="extraDescription(key, value)?.length > 0">                      
                      <span class="description extra" v-html="extraDescription(key, value)"></span>
                    </span>
                  </div>
                  <div v-if="isGroupForKey(value)">
                    <ul>
                      <li v-for="(groupOptValue, groupValueIndex) in optionsForGroup(key, value)"
                        v-bind:key="'groupvalue_' + groupValueIndex"
                      >
                        <input type="checkbox"
                          @change="onSelectOption(key, groupOptValue, $event)"
                          :value="!disabledOptions?.[key]?.[groupOptValue]"
                          :checked="!disabledOptions?.[key]?.[groupOptValue] && !implicitlyDisabledOptions?.[key]?.[groupOptValue]"
                          :disabled="!editingAllowed || implicitlyDisabledOptions?.[key]?.[value] || isNotSelectable(groupOptValue)">
                        <div class="thumbnail" :style="thumbnailStyle(groupOptValue)"></div>
                        <div class="text">
                          <span class="title">{{ titleForKey(groupOptValue) }}</span>
                          <br>
                          <span class="description">{{ descriptionForKey(groupOptValue) }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </template>
          </AccordionTile>
          <div v-if="optionKeysForStepKey(currentStepKey).length == 0">Bitte warten Sie, während die Daten synchronisiert werden.</div>
        </div>
      </WidgetRow>
      <WidgetRow class="actions">
        <AssortmentSaveButtons />
      </WidgetRow>
    </div>
    <div class="snackbar error" v-if="Object.keys(assortment.errors).length">Die Konfiguration enthält Fehler, bitte überprüfen Sie Ihre Einstellungen.</div>
  </div>
</template>

<script>

//import WidgetContainer from '../components/widgets/WidgetContainer.vue'
import WidgetRow from "../components/widgets/WidgetRow.vue"
import AccordionTile from '../components/ui/AccordionTile.vue'
import { AttachmentsMixin } from "@profineberater/configurator-clientlib"

const assortmentConfigProcess = {
  "basis": {
    title: "Basis",
    configurableKeys: {
      "element": {
        min: 1,
      },
      "material": {
        min: 1,
        depends: true
      },      
      "system": {
        min: 1,
        depends: true
      },
    }
  },
  "design": {
    title: "Design",
    configurableKeys: {
      "color": {
        hideAllCheckbox: true,
        dependencies: {
          "weiss": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "pvc"
              },
              {
                key: "material",
                value: "alu/pvc"
              }
            ]
          },
          "folienfarben": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "pvc"
              },
              {
                key: "material",
                value: "alu/pvc"
              }
            ]
          },
          "holzfarben": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "holz"
              },
              {
                key: "material",
                value: "holz/alu"
              }
            ]
          },
          "alufarben": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "alu/pvc"
              },
              {
                key: "material",
                value: "alu"
              }
            ]
          },
        }
      },
      "shellColor": {
        hideAllCheckbox: true,
        dependencies: {
          "holzfarben": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "holz"
              },
              {
                key: "material",
                value: "holz/alu"
              }
            ]
          },
          "alufarben": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "alu/pvc"
              },
              {
                key: "material",
                value: "alu"
              }
            ]
          },
        }
      },
      "sealColor": {
        title: "Dichtung (PVC Weiß)",
        hideAllCheckbox: true, 
        depends: true,
        // extraDescriptions: {
        //   "dichtung lichtgrau": "Für PVC Standard",
        //   "dichtung schwarz": "Für PVC Standard",
        //   "dichtung braun": "Für Holz und Holz/Alu",
        //   "dichtung schwarz (alu/pvc foliert)": "Für PVC foliert",
        //   "dichtung schwarz (alu/pvc rohfarbe)": "Für PVC Standard",
        //   "dichtung schwarz (alu)": "Für Alu",
        //   "dichtung schwarz (pvc foliert)": "Für PVC foliert",
        // },      
        requiresOneOf: [
          {
            key: "sealColor",
            value: "dichtung lichtgrau"
          },
          {
            key: "sealColor",
            value: "dichtung schwarz"
          },  
        ],
        dependencies: {
          "dichtung lichtgrau": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "pvc"
              },                           
            ]
          },
          "dichtung schwarz": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "alu/pvc"
              },
              {
                key: "material",
                value: "pvc"
              },
              {
                key: "material",
                value: "alu"
              },              
            ]
          },
          "dichtung schwarz (alu/pvc foliert)": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "alu/pvc"
              },
            ]
          },
          "dichtung schwarz (alu/pvc rohfarbe)": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "alu/pvc"
              },
            ]
          },
          "dichtung schwarz (alu)": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "alu"
              },
            ]
          },
          "dichtung schwarz (pvc foliert)": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "pvc"
              },
            ]
          },
          "dichtung braun": {
            requiresOneOf:
            [
              {
                key: "material",
                value: "holz"
              },
              {
                key: "material",
                value: "holz/alu"
              },
            ]
          }                              
        }
      },
      "handle": {
        hideAllCheckbox: true,
        dependencies: {
          "haustürgriffe": {
            requiresOneOf:
            [
              {
                key: "element",
                value: "haustür"
              },
            ]
          },
          "fenstergriffe": {
            requiresOneOf:
            [
              {
                key: "element",
                value: "fenster / fenstertür"
              },
              {
                key: "fenster / fenstertür",
                value: "fenstertür mit schwelle"
              },
            ]
          },
          "hst-griffe": {
            requiresOneOf:
            [
              {
                key: "element",
                value: "hebeschiebetür"
              },
            ]
          },
        }
      },
      "windowHardware": {
      }
    }
  },
  "glass": {
    title: "Glas",
    configurableKeys: {
      "glass": {
      },
      "ornamentGlass": {
      },
      "acousticGlass": {
      },
      "securityGlass": {
      },
    }
  },
  "extras": {
    title: "Extras",
    configurableKeys: {
      "rollerShutterRail": {
      },
      "ventilation": {
      },
      "windowLedgeInnerMaterial": {
      },
      "windowLedgeInnerModel": {
      },
      "windowLedgeInnerColor": {
      },
      "windowLedgeOuterColor": {
      },
    }
  },
  "blinds": {
    title: "Sichtschutz",
    configurableKeys: {
      "blindsType": {
      },
      "blindsMaterial": {
      },
      "blindsCaseColor": {
        hideAllCheckbox: true,
      },
      "blindsFinColor": {
      },
      "blindsDrive": {
      },
      "blindsDriveLocation": {
      }
    }
  },
}

import { RIGHTS, hasUserRight } from "@profineberater/configurator-clientlib"
import AssortmentSaveButtons from "../components/sidebar/AssortmentSaveButtons.vue"

export default {
  name: 'AssortmentPage',
  components: {
    AccordionTile,
    WidgetRow,
    AssortmentSaveButtons
},
  mixins: [AttachmentsMixin],
  data: function() {
    return {
      collapsed: {},
      currentStepKey: "basis"
    }
  },
  created() {
    this.$store.dispatch("user/fetchProfile");
    this.$store.dispatch("user/fetchAssortment")
  },
  computed: {
    isContentDbAvailable() {
      if (this.$store.state.ui.isOffline) {
        return window.$localContentDB != undefined
      }
      return this.$store.getters['db/contentDbAvailable']
    },
    teamEnabled() {
      return this.$store.getters["team/teamEnabled"]
    },
    editingAllowed() {
      return !this.teamEnabled || hasUserRight(RIGHTS.ASSORTMENT_RW)
    },
    dependencyMap() {
      let map = {}
      for (let key in this.configurableKeys) {
        let dependencies = this.configurableKeys[key].dependencies
        if (dependencies) {
          Object.keys(dependencies).forEach( optionValueWithDependency => {
            map[optionValueWithDependency] = { ...(map[optionValueWithDependency] || {}), ...dependencies[optionValueWithDependency] }
          })
        }
      }
      return map
    },
    optionValuesMap() {
      let map = this.$store.getters["configurationOptions/values"]
      if (!map) return {}
      // remove multiple groups like "holzfarben,alufarben"
      Object.keys(map).forEach( key => {
        let filteredValues = []
        map[key].forEach( value => {
          filteredValues.push(value)
        })
        map[key] = filteredValues
      })
      return map
    },
    visibleOptions() {
      let map = this.optionValuesMap
      return Object.keys(map).reduce( (all, key) => {
        all[key] = map[key].filter( v => !this.hiddenOptions.includes(v) && v.length > 0)
        return all
      }, {})
    },
    allOptionValues() {
      return Object.keys(this.optionValuesMap).reduce( (all, key) => {
        all = all.concat(this.optionValuesMap[key])
        return all
      }, [])
    },
    steps() {
      return Object.keys(assortmentConfigProcess).map( key => {
        return {
          ...assortmentConfigProcess[key],
          ...{ key: key }
        }
      })
    },
    configuration() {
      let config = {}
      Object.keys(this.optionValuesMap).forEach( key => {
        this.optionValuesMap[key].forEach( v => {
          if (!config[key]) {
              config[key] = []
          }
          if (this.disabledOptions[key]?.[v] !== true) {
            config[key].push(v)
          }
        })
      })
      config.wildcard = Object.keys(this.configurableKeys).filter( k => this.configurableKeys[k].depends !== true )
      config.ignoreDisabled = true

      return config
    },
    nonSelectableOptions() {
      let nonSelectable = new Set()
      var metadata
      this.optionKeys.forEach( key => {
        this.optionValuesMap[key].forEach( value => {
          let optionValues = [value]
          if (this.isGroupForKey(value)) {
            optionValues = this.optionsForGroup(key, value)
          }
          optionValues.forEach( optionValue => {
            metadata = this.metadataForKey(optionValue)
            if (metadata?.isNone || metadata?.isNotExcludable) {
              nonSelectable.add(optionValue)
            }
          })
        })
      })
      return Array.from(nonSelectable)
    },
    hiddenOptions() {
      let hiddenSet = new Set()
      var metadata
      this.optionKeys.forEach( key => {
        this.optionValuesMap[key].forEach( value => {
          let optionValues = [value]
          if (this.isGroupForKey(value)) {
            optionValues = this.optionsForGroup(key, value)
          }
          optionValues.forEach( optionValue => {
            metadata = this.metadataForKey(optionValue)
            if (metadata?.isHiddenInAssortment) {
              hiddenSet.add(optionValue)
            }
          })
        })
      })
      return Array.from(hiddenSet)
    },
    optionKeys() {
      return Object.keys(this.optionValuesMap).filter( key => Object.keys(this.configurableKeys).includes(key))
    },
    disabledOptions() {
      let availableKeys = Object.keys(this.configurableKeys).reduce( (avail, key) => {
        avail[key] = {}
        return avail
      }, {})
      let opts = { ...availableKeys,  ...this.$store.getters["user/disabledOptions"]}
      return opts
    },
    assortment() {
      return this.$store.getters["user/assortment"]
    },
    isAllOptionsChecked() {
      let result = {}
      this.optionKeys.forEach(key => {
        result[key] = !this.optionValuesMap[key].reduce( (p, c) => {
          return this.disabledOptions[key][c] || p
        }, false)
      })
      return result
    },
    implicitlyDisabledOptions() {
      let disabledOpts = {}
      this.optionKeys.forEach( key => {
        this.optionValuesMap[key].forEach( value => {
          (disabledOpts[key] ||= {})[value] = !this.isGroupForKey(key) && !this.optionsForKeyMap[key].includes(value)
          if (this.dependencyMap[value]) {
            let requiresOneOf = this.dependencyMap[value].requiresOneOf
            if (requiresOneOf?.length) {
              var numberOfRequirementsMet = requiresOneOf.reduce( (count, requirement) => {
                if (!this.disabledOptions[requirement.key]?.[requirement.value]) {
                  count += 1
                }
                return count
              }, 0);
              var conditionMet = (numberOfRequirementsMet > 0);
              (disabledOpts[key] ||= {})[value] = !conditionMet
            }
          }
        })
        if (disabledOpts[key]) {
          let disabledValues = Object.keys(disabledOpts[key]).filter( k => disabledOpts[key][k] == true)
          let possibleValues = this.optionValuesMap[key]
          if (disabledValues.length == possibleValues.length) {
            disabledOpts[key]._all = true
          }
        }
      })
      return disabledOpts
    },
    optionsForKeyMap() {
      let map = {}
      this.optionKeys.forEach( key => {
          map[key] = this.optionsForKey(key)
      })
      return map
    },
    configurableKeys() {
      let keys = Object.keys(assortmentConfigProcess).reduce( (allKeys, step) => {
        allKeys = { ...allKeys, ...assortmentConfigProcess[step].configurableKeys }
        return allKeys
      }, {})
      return keys
    }
  },
  methods: {
    optionKeysForStepKey(step) {
      //return Object.keys(this.optionValuesMap).filter( key => Object.keys(assortmentConfigProcess[step].configurableKeys).includes(key))
      return Object.keys(assortmentConfigProcess[step].configurableKeys)
    },
    toggleCollapsed(key) {
      if (this.collapsed[key]) {
        this.collapsed[key] = false
      } else {
        this.collapsed[key] = true
      }
    },
    checkValidity() {
      let isValid = true
      let errorMap = {}
      Object.keys(this.configurableKeys).forEach( key => {
        let min = this.configurableKeys[key].min
        if (min !== undefined) {
          var numberOfDisabledOptions = 0
          var numberOfPossibleOptions = this.optionsForKeyMap[key]?.length || 0
          this.optionsForKeyMap[key].forEach( opt => {
            if (this.disabledOptions[key][opt] === true) {
              numberOfDisabledOptions++
            }
          })
          if ((numberOfPossibleOptions - numberOfDisabledOptions) < min) {
            isValid = false
            errorMap[key] = min == 1 ? "Es muss mindestens eine Option ausgewählt sein." : `Es müssen mindestens ${min} Optionen ausgewählt sein.`
          }
        }
        let requiresOneOf = this.configurableKeys[key].requiresOneOf        
        if (requiresOneOf) {
          // disabledOptions is a map containts all options that are disabled. The map contains the disabled option as key and true or false as value.
          // If the key is not present, the option is enabled
          // requiresOneOf contains an array with the keys that are required to be enabled
          // If all required options are disabled, the current option is invalid
          // check if disabledOptions[key] is true for all required options
          let numberOfRequirementsMet = requiresOneOf.reduce( (count, requirement) => {
            if (!this.disabledOptions[requirement.key]?.[requirement.value] && 
                !this.implicitlyDisabledOptions?.[requirement.key]?.[requirement.value]
            ) {
              count += 1
            }
            return count
          }, 0);
          if (numberOfRequirementsMet == 0) {
            isValid = false
            errorMap[key] = "Es muss mindestens eine Option ausgewählt sein."
          }        
        }
      })
      this.$store.commit("user/assortmentIsValid", isValid)
      this.$store.commit("user/assortmentError", errorMap)
    },
    metadataForKey(key) {
      return this.$store.getters["configurationOptions/metadataForKey"](key)
    },
    titleForKey(key) {
      let customTitle = assortmentConfigProcess[this.currentStepKey]?.configurableKeys[key]?.title
      if (customTitle) {
        return customTitle
      }
      return this.metadataForKey(key)?.title || key
    },
    isNoneForKey(key) {
      return this.metadataForKey(key)?.isNone
    },
    isGroupForKey(key) {
      return this.metadataForKey(key)?.isGroup
    },
    isNotSelectable(key) {
      return this.nonSelectableOptions.includes(key)
    },
    isHidden(key) {
      return this.hiddenOptions.includes(key)
    },
    descriptionForKey(key) {
      return this.metadataForKey(key)?.productInfoShort || ""
    },
    extraDescription(category, key) {
      return assortmentConfigProcess[this.currentStepKey].configurableKeys[category].extraDescriptions?.[key] || ""
    },     
    isAllCheckboxHidden(key) {
      return assortmentConfigProcess[this.currentStepKey].configurableKeys[key].hideAllCheckbox
    }, 
    isAllOptionsNonSelectable(key) {
      return this.nonSelectableOptions?.filter( o => this.optionsForKeyMap[key]?.includes(o))?.length == this.optionsForKeyMap[key]?.length
    },
    onSelectOption(key, optionId, e) {
      if (!e.target.checked) {
        this.disabledOptions[key][optionId] = true
      } else {
        delete this.disabledOptions[key][optionId]
      }
      this.checkValidity()
      this.$store.commit("user/disabledOptions", this.disabledOptions)      
    },
    toggleAllOptionsForKey(key, e) {
      let checked = e.target.checked
      this.optionValuesMap[key].forEach( value => {
        if (this.nonSelectableOptions.includes(value)) {
          this.disabledOptions[key][value] = null
        } else {
          this.disabledOptions[key][value] = !checked
        }
      })
      this.checkValidity()
      this.$store.commit("user/disabledOptions", this.disabledOptions)
    },
    optionsForKey(key) {
      return this.$store.getters["configurationOptions/optionsForKey"](key, this.configuration).map( o => o.key )
    },
    optionsForGroup(key, value) {
      let groupOpts = this.$store.getters["configurationOptions/optionsForGroup"](key, value)
      return groupOpts
    },
    thumbnailStyle(optionId) {
      let s = {}
      let url = this.attachments[this.attachmentIdForIdAndKey(optionId, "url")]
      let metadata = this.metadataForKey(optionId)
      if (metadata?.isNone) {
        s.backgroundImage = `url(${require('@/assets/img/none.png')})`
        return s
      }
      if (url) {
        s.backgroundImage = `url(${url})`
      } else {
        if (metadata?.hex) {
          s.backgroundColor = metadata.hex
        }
      }
      return s
    },
  },
  mounted() {
    this.loadConfigurationOptionsAttachments()
  },
  watch: {
    optionValuesMap() {
      if (!this.attachments?.length) {
        this.loadConfigurationOptionsAttachments()
      }
    }
  }
}
</script>
<style scoped>
  .assortment-config {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .accordions {
    width: 75%;
  }
  input[type=checkbox] {
    margin-right: 20px;
  }
  li {
    text-align: left;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid var(--c-bright-grey);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  li.group {
    display: block;
    flex-direction: column;
  }

  li li {
    padding: 20px 0 20px 20px;
  }
  li.disabled {
    opacity: 0.5;
  }
  li:last-child {
    border: none;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  span.key {
    color: var(--text-color);
  }
  .disabled {
    opacity: 0.25;
    pointer-events: none;
  }
  .widget-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .error, span.error {
    color: var(--error-color);
    font-weight: 500;
  }
  .accordion-action {
    cursor: pointer;
  }
  .accordion-action svg {
    transition: all 0.25s ease-in-out;
  }
  .plus-rotated {
    transform: rotate(45deg);
  }
  .accordion h5 {
    margin: 0;
  }

  .accordion span.description {
    color: var(--text-color);
    margin: 0;
    font-weight: normal;
    letter-spacing: 0.5px;
    font-size: 12px;
    display: inline-block;
    padding-top: 8px;
  }

  span.title {
    font-weight: 700;
  }
  .accordion li span.description:not(.extra), .accordion li span.title {
    margin-left: 20px;
  }
  .accordion > li.group span.title {
    margin-left: 0;
  }
  .steps {
    background: #edeff3;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.15);
    offset-position: -1;
    border-radius: 7px;
    font-weight: 400;
    letter-spacing: 0.35px;
    font-size: 13px;
    min-height: 44px;
    max-height: 44px;
    margin: 0 0 40px 20px;
    color: var(--darkest-text-color);
    margin-top: 1px;
    display: inline-block;
    margin: auto;
  }
  .steps ul {
    margin: 0;
    list-style-type: none;
    display: block;
  }
  .steps li {
    cursor: pointer;
    float: left;
    height: 44px;
    line-height: 44px;
    border-radius: 7px;
    margin: 0;
    padding: 0 20px;
  }
  .steps li:last-of-type {
    margin: 0 2px 0 2px;
  }

  .steps .numbers-only li {
    padding: 0 12px;
    width: 44px;
  }
  .steps li.active {
    background: #fff;
    height: 40px;
    line-height: 40px;
    margin-top: 2px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(63, 95, 137, 0.15);
  }
  .error-description {
    font-weight: 500;
  }

  .snackbar {
    position: fixed;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
  }
  .snackbar.error {
    background-color: var(--error-color);
    color: #fff;
    font-weight: 700;
    font-size: 13px;

  }
  .thumbnail {
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
    background-size: contain !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    border-radius: 3px;
  }

  input[type=checkbox] {
    cursor: pointer;
  }

  .actions {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }
  :deep(.action-buttons) {
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  :deep(.action-buttons .button) {
    width: 100%;
    margin-right: 0;
  }

  :deep(.action-buttons button:first-of-type) {
    margin-bottom: 10px;
  }

  @media only screen and (max-width : 1280px) {
    .accordions {
      width: 90%;
    }

    .steps li {
      padding: 0 10px;
    }

    li li {
      padding: 20px 0;
    }

    .actions {
      display: flex;
    }
  }
</style>
