import { createWebHistory, createRouter } from "vue-router"
import DashboardPage from "@/views/DashboardPage.vue"
import AssortmentPage from "@/views/AssortmentPage.vue"
import ProjectsPage from "@/views/ProjectsPage.vue"
import ProfilePage from "@/views/ProfilePage.vue"
import LegalPage from "@/views/LegalPage.vue"
import GlobalAdminPage from "@/views/GlobalAdminPage.vue"
import AppsPage from "@/views/AppsPage.vue"
import ImprintPage from "@/views/ImprintPage.vue"
import PrivacyPage from "@/views/PrivacyPage.vue"
import ReleaseNotesPage from "@/views/ReleaseNotesPage.vue"
import TosPage from "@/views/TosPage.vue"
import ReportPage from "@/views/ReportPage.vue"

import TeamPage from "@/views/TeamPage.vue"
import CreateTeamPage from "@/views/CreateTeamPage.vue"
import CreateTeamSuccessPage from "@/views/CreateTeamSuccessPage.vue"
//import LeaveTeamPage from "@/views/LeaveTeamPage.vue"
import RemoveTeamPage from "@/views/RemoveTeamPage.vue"

import PaymentPage from "@/views/PaymentPage.vue"
import CompanyPage from "@/views/CompanyPage.vue"
import ReloadAndRedirectPage from "@/views/ReloadAndRedirectPage.vue"

import AppLayout from "@/AppLayout.vue"
import WelcomeSidebar from "@/components/sidebar/WelcomeSidebar.vue"
import AssortmentSidebar from "@/components/sidebar/AssortmentSidebar.vue"
import GlobalAdminSidebar from "@/components/sidebar/GlobalAdminSidebar.vue"
import ProfileSidebar from "@/components/sidebar/ProfileSidebar.vue"
import CompanySidebar from "@/components/sidebar/CompanySidebar.vue"
import TeamSidebar from "@/components/sidebar/TeamSidebar.vue"
import LegalSidebar from "@/components/sidebar/LegalSidebar.vue"
import ProjectsSidebar from "@/components/sidebar/ProjectsSidebar.vue"
import AppsSidebar from "@/components/sidebar/AppsSidebar.vue"
import PaymentSidebar from "@/components/sidebar/PaymentSidebar.vue"
import ReportSidebar from "@/components/sidebar/ReportSidebar.vue"

import { LoginPage, LogoutPage, ROLES, hasUserRole } from "@profineberater/configurator-clientlib"

import store from "@/store"

const routes = [
  {
    path: "/login",
    meta: {
      public: true,
    },
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
  },
  {
    path: "/",
    name: "Dashboard",
    meta: { pageTitle: `Dashboard | ${process.env.VUE_APP_COMPANY_NAME}` },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Dashboard Page",
        components: {
          default: DashboardPage,
          sidebar: WelcomeSidebar,
        }
      }
    ]
  },
  {
    path: '/report',
    meta: {
      tabBarHidden: true,
      needsUserRole: ROLES.ADMIN_ROLE_ADMIN
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Report Page",
        components: {
          default: ReportPage,
          sidebar: ReportSidebar,
        }
      }
    ]
  },
  {
    path: "/assortment",
    name: "Sortiment",
    meta: {
      pageTitle: `Sortiment | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        path: '',
        name: "Assortment Page",
        components: {
          default: AssortmentPage,
          sidebar: AssortmentSidebar,
        }
      }
    ]
  },
  {
    path: "/projects",
    name: "Projekte",
    meta: {
      pageTitle: `Projekte | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Projects Page",
        path: '',
        components: {
          default: ProjectsPage,
          sidebar: ProjectsSidebar,
        }
      }
    ]
  },
  {
    path: "/globaladmin",
    name: "Global Admin",
    meta: {
      pageTitle: `Global Admin | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "GlobalAdminPage Page",
        path: '',
        components: {
          default: GlobalAdminPage,
          sidebar: GlobalAdminSidebar,
        }
      }
    ]
  },
  {
    path: "/profile",
    name: "Profil",
    meta: {
      pageTitle: `Profil | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Profile Page",
        path: '',
        components: {
          default: ProfilePage,
          sidebar: ProfileSidebar,
        }
      }
    ]
  },
  {
    path: "/team",
    name: "Team",
    meta: {
      pageTitle: `Team | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Team Page",
        path: '',
        components: {
          default: TeamPage,
          sidebar: TeamSidebar,
        }
      }
    ]
  },
  {
    path: "/team/create",
    name: "Team erstellen",
    meta: {
      pageTitle: `Team erstellen | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Create Team Page",
        path: '',
        components: {
          default: CreateTeamPage,
          sidebar: TeamSidebar,
        }
      }
    ]
  },
  {
    path: "/team/create/success",
    name: "Team erstellt",
    meta: {
      pageTitle: `Team erstellt | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Create Team Success Page",
        path: '',
        components: {
          default: CreateTeamSuccessPage,
          sidebar: TeamSidebar,
        }
      }
    ]
  },
  // For now, the teamleader has to remove the members manually on request
  // {
  //   path: "/team/leave",
  //   name: "Team verlassen",
  //   meta: {
  //     pageTitle: `Team verlassen | ${process.env.VUE_APP_COMPANY_NAME}`,
  //   },
  //   component: AppLayout,
  //   children: [
  //     {
  //       name: "Leave Team Page",
  //       path: '',
  //       components: {
  //         default: LeaveTeamPage,
  //         sidebar: TeamSidebar,
  //       }
  //     }
  //   ]
  // },
  {
    path: "/team/remove",
    name: "Team löschen",
    meta: {
      pageTitle: `Team löschen | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Remove Team Page",
        path: '',
        components: {
          default: RemoveTeamPage,
          sidebar: TeamSidebar,
        }
      }
    ]
  },
  {
    path: "/company",
    name: "Firma",
    meta: {
      pageTitle: `Firma | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Company Page",
        path: '',
        components: {
          default: CompanyPage,
          sidebar: CompanySidebar,
        }
      }
    ]
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      pageTitle: `Zahlungsdaten | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Payment Page",
        path: '',
        components: {
          default: PaymentPage,
          sidebar: PaymentSidebar,
        }
      }
    ]
  },
  {
    path: "/legal",
    name: "Rechtliches",
    meta: {
      pageTitle: `Rechtliches | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Legal Page",
        path: '',
        components: {
          default: LegalPage,
          sidebar: LegalSidebar,
        }
      }
    ]
  },
  {
    path: "/apps",
    name: "Apps",
    meta: {
      pageTitle: `Apps | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Apps Page",
        path: '',
        components: {
          default: AppsPage,
          sidebar: AppsSidebar,
        }
      }
    ]
  },
  {
    path: "/tos",
    name: "Nutzungsbedingungen",
    meta: {
      pageTitle: `Nutzungsbedingungen | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "TOS Page",
        path: '',
        components: {
          default: TosPage,
          sidebar: LegalSidebar,
        }
      }
    ]
  },
  {
    path: "/imprint",
    name: "Impressum",
    meta: {
      pageTitle: `Impressum | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Imprint Page",
        path: '',
        components: {
          default: ImprintPage,
          sidebar: LegalSidebar,
        }
      }
    ]
  },
  {
    path: "/privacy",
    name: "Datenschutzhinweise",
    meta: {
      pageTitle: `Datenschutzhinweise | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Privacy Page",
        path: '',
        components: {
          default: PrivacyPage,
          sidebar: LegalSidebar,
        }
      }
    ]
  },{
    path: "/releasenotes",
    name: "Release Notes",
    meta: {
      pageTitle: `Release Notes | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Release Notes Page",
        path: '',
        components: {
          default: ReleaseNotesPage,
          sidebar: ProfileSidebar,
        }
      }
    ]
  },{
    path: "/redirect",
    name: "Reload and Redirect",
    meta: {
      pageTitle: `Redirect | ${process.env.VUE_APP_COMPANY_NAME}`,
    },
    component: AppLayout,
    children: [
      {
        name: "Reload and Redirect",
        path: '',
        components: {
          default: ReloadAndRedirectPage
        }
      }
    ]
  },


];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

router.beforeEach(
  async (to, from, next) => {

    let isPublic = to.meta?.public
    if (!isPublic) {
      // We wait for Keycloak init, then we can call all methods safely
      while (!window.$keycloak || window.$keycloak.createLoginUrl === null) {
        console.log("waiting")
        await sleep(100)
      }
      if (window?.$keycloak?.authenticated) {
        if (to.meta?.needsUserRole) {
          if (!hasUserRole(to.meta?.needsUserRole)) {
            next({ path: "/" })
            return
          }
        }
        if (store.getters["ui/reload"] && to.path != "/redirect") {
          next({ path: '/redirect', query: { path: to.path } })
        } else {
          next()
        }
      } else {
        next({ path: "/login" })
      }
    } else {
      next()
    }
  }
)

router.afterEach( (to) => {
  store.commit("info/isInfoBarShown", to.meta.showInfoBar != null)
  store.commit("info/infoBarPath", to.meta.showInfoBar)
  document.title = to.meta.pageTitle || process.env.VUE_APP_COMPANY_NAME
})

export default router;